import React from 'react'
import { connect } from 'react-redux'
import styles from '../../../styles/Buttons.module.scss'
import SpinnerIcon from '../../../../../assets/svg/spinner-rolling-white.svg'

const classNames = require('classnames')

function Button({
  label,
  onClick,
  color = 'primary',
  fullWidth,
  width,
  height,
  fontSize,
  radius,
  size,
  iconBefore,
  iconAfter,
  disabled = false,
  loading = false,
  className,
  icon,
  simple,
  formatted = true,
}) {
  const buttonIcon = iconBefore || icon

  return (
    <button
      className={classNames(
        {
          [styles[`Button___${size}`]]: size,
          [styles[`Button___${color}`]]: color,
          [styles.Button___loading]: loading,
          [styles.Button___icon]: icon,
          [styles.Button___simple]: simple,
          [styles.Button___noFormatted]: !formatted,
        },
        styles.Button,
        className
      )}
      type="button"
      onClick={onClick}
      style={{
        fontSize,
        width: fullWidth ? '100%' : width,
        height,
        borderRadius: radius,
      }}
      disabled={disabled}
    >
      {loading && <SpinnerIcon className={classNames(styles.Button_icon, styles.Button_icon___loading)} />}
      {buttonIcon !== undefined && <div className={styles.Button_icon}>{buttonIcon}</div>}
      {label && <span className={styles.Button_label}>{label}</span>}
      {iconAfter !== undefined && <div className={styles.Button_icon}>{iconAfter}</div>}
    </button>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps)(Button)
