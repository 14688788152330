import { createSelector } from 'reselect'
import _ from 'lodash'

export const selectBlobUrlObjects = state => state.media.blobUrls

export const selectMediaBlobUrl = createSelector([selectBlobUrlObjects, (state, url) => url], (blobUrlObjects, url) => {
  const object = _.find(blobUrlObjects, _object => _object.url === url)

  if (!object) {
    return null
  }

  return object.blobUrl
})
