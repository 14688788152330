import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import OrderByDropdown from './OrderByDropdown'
import SearchBar from './SearchBar'

function Header() {
  return (
    <div className="search-header">
      <SearchBar />
      <OrderByDropdown />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Header)
