import React from 'react'
import { CircularProgress, Dialog as MuiDialog } from '@material-ui/core'
import { connect } from 'react-redux'
import * as actionCreators from '../../../state/actions'
import styles from '../../../styles/Dialog.module.scss'
import Button from './Button'

const classNames = require('classnames')

function Dialog({
  title,
  text,
  onConfirm,
  className,
  cancelButton = true,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  open,
  setOpen,
  texts,
  closeOnConfirm = true,
  textColor,
  loading,
  icon,
  width,
  spacing,
  children,
  animation = true,
}) {
  const handleCancel = () => {
    if (onCancel) onCancel()
    setOpen(false)
  }

  const handleConfirm = async () => {
    if (onConfirm) await onConfirm()
    if (closeOnConfirm) setOpen(false)
  }

  return (
    <MuiDialog
      PaperProps={{
        className: classNames(styles.Dialog, className, {
          [styles.Dialog___noAnimation]: !animation,
        }),
        style: {
          width,
          padding: spacing,
        },
      }}
      open={open}
      onClose={handleCancel}
      className={classNames({
        [styles.Dialog_container___noAnimation]: !animation,
      })}
    >
      <div className={styles.Dialog_title}>{title}</div>
      {text && (
        <div className={styles.Dialog_text} style={{ color: textColor }}>
          {!loading && (
            <>
              {icon && <div className={styles.Dialog_icon}>{icon}</div>}
              {text}
            </>
          )}
          {loading && <CircularProgress style={{ color: 'black' }} size={100} />}
        </div>
      )}
      <div className={styles.Dialog_content} style={{ color: textColor }}>
        {children}
      </div>
      <div className={styles.Dialog_actions}>
        {cancelButton && (
          <Button
            label={cancelButtonText ?? texts.cancel}
            disabled={loading}
            size="big"
            color="white"
            onClick={handleCancel}
            fullWidth
          />
        )}
        <Button label={confirmButtonText ?? texts.ok} disabled={loading} size="big" onClick={handleConfirm} fullWidth />
      </div>
    </MuiDialog>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
})(Dialog)
