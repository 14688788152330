import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import Pagination from './Pagination'
import ProjectCardsList from './ProjectCardsList'

function SearchContent({ isSearching, entities, texts }) {
  const length = entities ? Object.keys(entities).length : 0

  if (isSearching) {
    return (
      <div className="search-content">
        <div className="search-results--empty" />
      </div>
    )
  }

  return (
    <div className="search-content">
      {entities && (
        <div className="search-results-stats">
          {length} {length === 1 ? texts.result : texts.results}
        </div>
      )}

      {entities && length > 0 ? (
        <>
          <ProjectCardsList entities={entities} />
          <Pagination />
        </>
      ) : (
        <div className="search-results--empty">{texts.no_projects_found}</div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    entities: state.renaultProjectMode.search.entities,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(SearchContent)
