import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import BlobUrlImage from '../../Common/BlobUrlImage'

function ProjectBrand({ project }) {
  const [brandUrl, setBrandUrl] = useState(null)

  useEffect(() => {
    if (project) {
      const url = project.brand
      if (url !== brandUrl) {
        setBrandUrl(url)
      }
    }
  }, [project])

  const imageHtml = useMemo(() => {
    return brandUrl ? (
      <BlobUrlImage url={brandUrl} />
    ) : (
      <div className="project-card__brand-placeholder">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <circle
            cx="604"
            cy="217"
            r="10"
            fill="#D8D8D8"
            fillRule="evenodd"
            stroke="#000"
            strokeOpacity=".1"
            transform="translate(-594 -207)"
          />
        </svg>
      </div>
    )
  }, [brandUrl])

  return <div className="project-card__brand">{imageHtml}</div>
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProjectBrand)
