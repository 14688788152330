import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import MilestoneIcon from '../../../../../../assets/svg/milestone.svg'
import ExplorationPhaseIcon from '../../../../../../assets/svg/exploration-phase.svg'

function ProjectCounters({ project, texts }) {
  const { configuration_categories_count: counters } = project

  return (
    <div className="project-card__counters">
      {_.has(counters, 'milestone') && (
        <div className="project-card-counter project-card-counter--milestone">
          <span className="project-card-counter__icon">
            <MilestoneIcon width="12" />
          </span>
          <span className="project-card-counter__text">
            {counters.milestone}{' '}
            {counters.milestone === 1
              ? texts.configuration_category_milestone_title
              : texts.configuration_category_milestone_title_plural}
          </span>
        </div>
      )}

      {_.has(counters, 'exploration-phase') && (
        <div className="project-card-counter project-card-counter--exploration-phase">
          <span className="project-card-counter__icon">
            <ExplorationPhaseIcon width="12" />
          </span>
          <span className="project-card-counter__text">
            {counters['exploration-phase']}{' '}
            {counters['exploration-phase'] === 1
              ? texts.configuration_category_exploration_phase_title
              : texts.configuration_category_exploration_phase_title_plural}
          </span>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProjectCounters)
