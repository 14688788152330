import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import ProjectCard from './ProjectCard'

const classNames = require('classnames')

function ProjectCardsList({ className, entities }) {
  const [elements, setElements] = useState({})
  const [list, setList] = useState(null)

  useEffect(() => {
    // Elements caching system
    const newElements = { ...elements }
    const newList = _.map(entities, entity => {
      const { id } = entity
      const _el = !_.has(newElements, id) ? <ProjectCard key={_.uniqueId()} project={entity} /> : newElements[id]
      if (!_.has(newElements, id)) {
        newElements[id] = _el
      }

      return _el
    })

    setElements(newElements)
    setList(newList)
  }, [entities])

  return <div className={classNames('project-cards-grid', className)}>{list}</div>
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProjectCardsList)
