import React from 'react'
import { connect } from 'react-redux'

import Layout from 'layout'
import * as actionCreators from '../store/actions'

export class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
  }
}

export default connect(mapStateToProps, actionCreators)(NotFoundPage)
