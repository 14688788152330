import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import BlobUrlImage from '../../Common/BlobUrlImage'

function ProjectCover({ project }) {
  const [coverUrl, setCoverUrl] = useState(null)

  useEffect(() => {
    if (project) {
      const url = project.cover
      if (url !== coverUrl) {
        setCoverUrl(url)
      }
    }
  }, [project])

  const imageHtml = useMemo(() => <BlobUrlImage url={coverUrl} />, [coverUrl])

  return <div className="project-card__cover">{imageHtml}</div>
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProjectCover)
