import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../store/actions'
import { isBase64String } from '../../../../../../va-corejs-v3/utils'
import { selectMediaBlobUrl } from '../../../../../store/selectors/media'

function BlobUrlImage({ url, blobUrl, alt, setMediaBlobUrl }) {
  useEffect(() => {
    if (url && !blobUrl && !isBase64String(url)) {
      setMediaBlobUrl(url)
    }
  }, [url])

  const urlToShow = useMemo(() => {
    if (blobUrl) {
      return blobUrl
    }

    if (url && isBase64String(url)) {
      return url
    }

    return null
  }, [blobUrl])

  if (urlToShow) {
    return <img src={urlToShow} alt={alt || ''} />
  }

  return <></>
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    blobUrl: selectMediaBlobUrl(state, ownProps.url),
  }
}

export default connect(mapStateToProps, actionCreators)(BlobUrlImage)
